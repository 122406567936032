const routes = [
  {
    path: '/settings/polymer-type',
    name: 'settings/polymer-type',
    component: () => import('@/views/Company/Settings/Polymer/PolymerTypeList.vue'),
    meta: {
      requiresAuth: true,
      key: '9001',
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: false,
        },
        {
          text: 'Polymer Type',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/polymer-grade',
    name: 'settings/polymer-grade',
    component: () => import('@/views/Company/Settings/Grade/GradeList.vue'),
    meta: {
      requiresAuth: true,
      key: '9002',
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: false,
        },
        {
          text: 'Grade',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/polymer-process',
    name: 'settings/polymer-process',
    component: () => import('@/views/Company/Settings/Process/ProcessList.vue'),
    meta: {
      requiresAuth: true,
      key: '9003',
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: false,
        },
        {
          text: 'Process',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/polymer-application',
    name: 'settings/polymer-application',
    component: () => import('@/views/Company/Settings/Application/ApplicationList.vue'),
    meta: {
      requiresAuth: true,
      key: '9004',
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: false,
        },
        {
          text: 'Application',
          active: true,
        },
      ],
    },
  },
]

export default routes
