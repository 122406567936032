// import checkPermission from '@/utils/utils'
import leads from './Leads'
import vendor from './Vendor/routesVendor'
import units from './Vendor/Units/routesUnit'
import questions from './Questions/questionRoutes'
import templates from './Template/templateRoutes'
import reports from './Reports'
import settings from './Settings'

const routes = [
  ...leads,
  ...vendor,
  ...units,
  ...questions,
  ...templates,
  ...reports,
  ...settings,
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Company/Dashboard/Dashboard.vue'),
    // beforeEnter: (to, from, next) => {
    //   checkPermission(to, from, next)
    // },
    meta: {
      requiresAuth: true,
      key: '0',
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company-users/list',
    name: 'company-users/list',
    component: () => import('@/views/Company/CreateUserForm/UsersList.vue'),
    // beforeEnter: (to, from, next) => {
    //   checkPermission(to, from, next)
    // },
    meta: {
      requiresAuth: true,
      key: '1001',
      pageTitle: 'List Company User',
      breadcrumb: [
        {
          text: 'Company Users',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/create-user',
    name: 'company/create-user',
    component: () => import('@/views/Company/CreateUserForm/CreateUsers.vue'),
    // beforeEnter: (to, from, next) => {
    //   checkPermission(to, from, next)
    // },
    meta: {
      requiresAuth: true,
      key: '1002',
      pageTitle: 'Company Create User',
      breadcrumb: [
        {
          text: 'Company Users',
          active: false,
          to: '/company-users/list',
        },
        {
          text: 'Add New',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/user/edit-user/:id',
    name: 'company/user/edit-user',
    component: () => import('@/views/Company/CreateUserForm/CreateUsers.vue'),
    // beforeEnter: (to, from, next) => {
    //   checkPermission(to, from, next)
    // },
    meta: {
      requiresAuth: true,
      key: '1003',
      pageTitle: 'Company User Edit',
      breadcrumb: [
        {
          text: 'Company Users',
          active: false,
          to: '/company-users/list',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/user-type-permission-settings',
    name: 'company/user-type-permission-settings',
    component: () => import('@/views/Company/ManageUsersPermissions/UserTypePermissions.vue'),
    meta: {
      requiresAuth: true,
      key: '1004',
      pageTitle: 'Permission Settings',
      breadcrumb: [
        {
          text: 'User Permissions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company-users/show-rights',
    name: 'company-users/show-rights',
    component: () => import('@/views/Company/ManageUsersPermissions/UserTypePermissions.vue'),
    meta: {
      requiresAuth: true,
      key: '1006',
      pageTitle: 'Permission Settings',
      breadcrumb: [
        {
          text: 'Company Users',
          active: false,
          to: '/company-users/list',
        },
        {
          text: 'Manage Rights',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company-users/user-role/list',
    name: 'company-users/user-role/list',
    component: () => import('@/views/Company/Roles/RoleList.vue'),
    meta: {
      requiresAuth: true,
      key: '1007',
      pageTitle: 'Roles List',
      breadcrumb: [
        {
          text: 'Company Users',
          active: false,
          to: '/company-users/list',
        },
        {
          text: 'Roles List',
          active: true,
        },
      ],
    },
  },

]

export default routes
