import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import companyStore from '../views/SuperAdmin/CreateCompanyForm/companyStore'
import getCountryList from './countryState'
import userPermission from '../views/ManageUsersPermissions/userPermission'
import userManage from '../views/SuperAdmin/CreateUserForm/UserManage'
import CompanyuserManage from '../views/Company/CreateUserForm/UserManage'
import CompanyPermission from '../views/Company/ManageUsersPermissions/userPermission'
import ManageLeads from '../views/Company/Leads/manageLeads'
import PasswordStore from '../views/PasswordReset/passwordStore'
import DashboardApi from '../views/Company/Dashboard/DashboardApi'
import vendorStore from '../views/Company/Vendor/vendorStore'
import activityStore from '../views/Company/Vendor/activityStore'
import unitStore from '../views/Company/Vendor/Units/unitStore'
import opportunityStore from '../views/Company/Opportunity/opportunityStore'
import questionsStore from '../views/Company/FormQuestions/questionsStore'
import rolesStore from '../views/Company/Roles/storeRoles'
import servicesStore from '../views/SuperAdmin/Services/storeService'
import ReportStore from '../views/Company/Reports/ReportStore'
import SettingStore from '../views/Company/Settings/SettingStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    companyStore,
    getCountryList,
    userPermission,
    userManage,
    CompanyuserManage,
    CompanyPermission,
    ManageLeads,
    PasswordStore,
    DashboardApi,
    vendorStore,
    activityStore,
    unitStore,
    opportunityStore,
    questionsStore,
    rolesStore,
    servicesStore,
    ReportStore,
    SettingStore,
  },
  strict: process.env.DEV,
})
