import axios from 'axios'
import constants from '@/constants'

export default {
  namespaced: true,
  state: {
    users: '',
  },
  getters: {},
  mutations: {},
  actions: {
    getOpportunityList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/lead-opportunity-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getOpportunityListByLeadId(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/opportunity-list-by-lead `, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getOpportunityDataById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/opporunity-detail`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeStatusOpportunity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/change-status`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeStatusLead(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/lead-change-status`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    opportunityStepStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/opportunity-status-list`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    stateQuantityCategory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/state-quantity-category`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getRevenueDataById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/activity/list-revenue`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    moveToEPR(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/move-to-epr`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    cloneOpportunity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${constants.COMPANY_API_PREFIX}/lead-generate/move-to-epr`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
